import { ADMIN_PATHNAMES, HOST_PATHNAMES, BROADCAST_DETAIL_PATH_REGEX } from './constants';
import { isProduction } from './environmentHelpers';

function isAuthorised(roles, pathname) {
    const isBroadcastPathname = BROADCAST_DETAIL_PATH_REGEX.test(pathname);
    const isAdminPathname = ADMIN_PATHNAMES.includes(pathname) || isBroadcastPathname;
    const isHostPathname = HOST_PATHNAMES.includes(pathname) || isBroadcastPathname;

    if ((isAdminPathname && roles.admin)
        || (isHostPathname && roles.host)) return true;

    return false;
}

function authRedirect(pathname = "") {
    const NTS_HOSTNAME = isProduction()
        ? "https://www.nts.live"
        : "https://www-integration.nts.live";
    const DESK_HOSTNAME = isProduction()
        ? "https://desk.ntslive.co.uk"
        : "https://desk-integration.ntslive.co.uk";

    const continueParam = encodeURIComponent(`${DESK_HOSTNAME}/${pathname}`);
    window.location.href = `${NTS_HOSTNAME}/join?continue=${continueParam}&embed=true`;
}

export {
    authRedirect,
    isAuthorised,
};
